export const scorcher = {
  themeName: 'Scorcher',
  themeImage:
    'https://is3-ssl.mzstatic.com/image/thumb/Music/7a/9c/05/mzi.gntmxwzu.tif/512x512bb.jpg',
  size: {
    base: 8,
    fontSize: [
      '0.625rem',
      '0.75rem',
      '0.875rem',
      '1rem',
      '1.125rem',
      '1.25rem',
      '1.375rem',
      '1.5rem',
      '1.75rem',
      '2rem',
      '2.5rem',
      '3rem',
      '4rem',
      '5rem',
      '6rem',
      '7rem',
    ],
    lineHeight: [
      '1rem',
      '1rem',
      '1.5rem',
      '1.5rem',
      '1.5rem',
      '2rem',
      '2rem',
      '2.5rem',
      '2.5rem',
      '3rem',
      '4rem',
      '4.5rem',
      '6rem',
      '7.5rem',
      '9rem',
      '10.5rem',
    ],
  },
  color: {
    primary: ['#04a4e2'],
    secondary: ['#078ac5'],
    tertiary: ['#63534f'],
    accent: ['#bf6140'],
    grayscale: ['#0e0c0b', '#ffffff'],
    background: ['#ddd6d4', '#ffffff'],
    foreground: ['#8c7873', '#3d2e29'],
  },
};

export const scorcherDark = {
  ...scorcher,
  themeName: 'Scorcher - Dark',
  color: {
    primary: ['#04b7fb'],
    secondary: ['#089cdd'],
    tertiary: ['#725f5a'],
    accent: ['#bf6140'],
    grayscale: ['#0e0c0b', '#ffffff'],
    background: ['#0e0c0b', '#39302d'],
    foreground: ['#8c7873', '#ebe2e0'],
  },
};
