import themeImage from '../img/record-3.jpg';

export const corner = {
  themeName: 'On the Corner',
  themeImage: themeImage,
  size: {
    base: 8,
    fontSize: [
      '0.625rem',
      '0.75rem',
      '0.875rem',
      '1rem',
      '1.125rem',
      '1.25rem',
      '1.375rem',
      '1.5rem',
      '1.75rem',
      '2rem',
      '2.5rem',
      '3rem',
      '4rem',
      '5rem',
      '6rem',
      '7rem',
    ],
    lineHeight: [
      '1rem',
      '1rem',
      '1.5rem',
      '1.5rem',
      '1.5rem',
      '2rem',
      '2rem',
      '2.5rem',
      '2.5rem',
      '3rem',
      '4rem',
      '4.5rem',
      '6rem',
      '7.5rem',
      '9rem',
      '10.5rem',
    ],
  },
  color: {
    primary: ['#e3cc03'],
    secondary: ['#648646'],
    tertiary: ['#6b6147'],
    accent: ['#bf4091'],
    grayscale: ['#0f0e0a', '#ffffff'],
    background: ['#e0dcd1', '#ffffff'],
    foreground: ['#8c8573', '#3d3829'],
  },
};

export const cornerDark = {
  ...corner,
  themeName: 'On the Corner - Dark',
  color: {
    primary: ['#fce303'],
    secondary: ['#70974f'],
    tertiary: ['#7a6f52'],
    accent: ['#bf4091'],
    grayscale: ['#0f0e0a', '#ffffff'],
    background: ['#0f0e0a', '#3d3829'],
    foreground: ['#8c8573', '#ebe8e0'],
  },
};
