import { createFontSize, createLineHeight } from './utils/createFontSize';
import themeImage from '../img/record.jpg';

export const themeTest = {
  themeName: 'Test',
  themeImage: themeImage,
  size: {
    base: 8,
    fontSize: createFontSize(),
    lineHeight: createLineHeight(),
  },
  color: {
    primary: ['#ff4242'],
    secondary: ['#CD2418'],
    tertiary: ['#B31F0F'],
    accent: ['#DECE00'],
    grayscale: ['#202028', '#E1E1E5'],
    background: ['#C1C1C8', '#ffffff'],
    foreground: ['#34343c', '#202028'],
  },
};

export const themeTestDark = {
  ...themeTest,
  color: {
    ...themeTest.color,
    primary: ['#ff4242'],
    secondary: ['#CD2418'],
    tertiary: ['#B31F0F'],
    accent: ['#DECE00'],
    background: ['#202028', '#34343c'],
    foreground: ['#66666d', '#E1E1E5'],
  },
};
